/* import { useSelector, useDispatch } from 'react-redux'

import useQuery from './useQuery'*/


/* function useAuth() {

	const dispatch = useDispatch()

	const navigate = useNavigate()

	const query = useQuery()

	const { token, signedIn } = useSelector((state) => state.auth.session)

	const signIn = async (values) => {
		try {
			
				const redirectUrl = query.get(REDIRECT_URL_KEY)
				navigate(redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath)
				return {
					status: 'success',
					message: ''
				}
			}
		} catch (errors) {
			return {
				status: 'failed',
				message: errors?.response?.data?.message || errors.toString()
			}
		}
	}

	const signUp = async (values) => {
		try {
			const resp = await apiSignUp(values)
			if (resp.data) {
				const { token } = resp.data
				dispatch(onSignInSuccess(token))
				if(resp.data.user) {
					dispatch(setUser(resp.data.user || { 
						avatar: '', 
						userName: 'Anonymous', 
						authority: ['USER'], 
						email: ''
					}))
				}
				const redirectUrl = query.get(REDIRECT_URL_KEY)
				navigate(redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath)
				return {
					status: 'success',
					message: ''
				}
			}
		} catch (errors) {
			return {
				status: 'failed',
				message: errors?.response?.data?.message || errors.toString()
			}
		}
	}

	const handleSignOut = ()  => {
		dispatch(onSignOutSuccess())
		dispatch(setUser(initialState))
		navigate(appConfig.unAuthenticatedEntryPath)
	}

	const signOut = async () => {
		await apiSignOut()
		handleSignOut()
	}
    
	return {
		authenticated: token && signedIn,
		signIn,
		signUp,
		signOut
	}
} */
import { onSignInSuccess, onSignOutSuccess } from 'store/auth/sessionSlice'
import { useSelector, useDispatch } from 'react-redux'
import appConfig from 'configs/app.config'
import { useNavigate } from 'react-router-dom'
import useQuery from './useQuery'
import { setUser, initialState } from 'store/auth/userSlice'
import Cookies from 'js-cookie'

import { REDIRECT_URL_KEY } from 'constants/app.constant'
import React, {
	useState,
	useEffect,
	useMemo,
	useContext,
	createContext,
} from "react";
import queryString from "query-string";
import {
	getAuth,
	onAuthStateChanged,
	signOut as authSignOut,
	createUserWithEmailAndPassword,
	signInWithEmailAndPassword,
	signInWithPopup,
	GoogleAuthProvider,
	FacebookAuthProvider,
	TwitterAuthProvider,
	GithubAuthProvider,
	sendEmailVerification,
	checkActionCode,
	applyActionCode,
	getAdditionalUserInfo,
	updateEmail as authUpdateEmail,
	updateProfile as authUpdateProfile,
	updatePassword as authUpdatePassword,
	sendPasswordResetEmail as authSendPasswordResetEmail,
	confirmPasswordReset as authConfirmPasswordReset,
} from "firebase/auth";
import { firebaseApp } from "../firebase";
import { useUser, createUser, updateUser } from "../db.js";
//import PageLoader from "./../components/PageLoader";
import { apiSignIn, apiSignOut, apiSignUp } from 'services/AuthService'

import analytics from "../analytics";

// Whether to merge extra user data from database into `auth.user`
const MERGE_DB_USER = true;
// Whether to send email verification on signup
const EMAIL_VERIFICATION = true;
// Whether to connect analytics session to `user.uid`
const ANALYTICS_IDENTIFY = true;

// Initialize Firebase auth
const auth = getAuth(firebaseApp);

// Create a `useAuth` hook and `AuthProvider` that enables
// any component to subscribe to auth and re-render when it changes.
const authContext = createContext();

export const useAuth = () => useContext(authContext);


// This should wrap the app in `src/pages/_app.js`
export function AuthProvider({ children }) {
	const auth = useAuthProvider();
	return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

// Hook that creates the `auth` object and handles state
// This is called from `AuthProvider` above (extracted out for readability)
function useAuthProvider() {
	// Store auth user in state
	// `user` will be object, `null` (loading) or `false` (logged out)
	//const [user] = useState(initialState);
	const navigate = useNavigate();
	const dispatch = useDispatch()
	const query = useQuery()
	const [user, setUserAuth] = useState(null);

	//let user = useSelector((state) => state.auth.user);
	const { token, signedIn } = useSelector((state) => state.auth.session)
	// Merge extra user data from the database
	// This means extra user data (such as payment plan) is available as part
	// of `auth.user` and doesn't need to be fetched separately. Convenient!
	let finalUser = useMergeExtraData(user, { enabled: MERGE_DB_USER });
	finalUser = useFormatUser(finalUser);
	useIdentifyUser(finalUser, { enabled: ANALYTICS_IDENTIFY });
	// Connect analytics session to user
	finalUser && finalUser.uid && finalUser.avatarUrl && dispatch(setUser(finalUser))

	// Handle response from auth functions (`signup`, `signin`, and `signinWithProvider`)
	const handleAuth = async (response, values) => {
		try {
			const { user } = response;
			const { isNewUser } = getAdditionalUserInfo(response);
			
			// Ensure Firebase user is ready before we continue
			await waitForFirebase(user.uid);


			// Create the user in the database if they are new
			if (isNewUser) {
				await createUser(user.uid, { email: user.email });
				// Send email verification if enabled
				if (EMAIL_VERIFICATION) {
					sendEmailVerification(auth.currentUser);
				}
			}

			if (user) {
				const token = await user.getIdToken(true);
				const registeredUser = {
					id: user.uid,
					avatarUrl: user.avatarUrl,
					username: user.username,
					email: user.email,
					uid: user.uid,
					authority: ['admin'],
					accountUserName: 'admin',
					...user
				}
			
				dispatch(onSignInSuccess(token))
				setUserAuth(registeredUser);
				dispatch(setUser(registeredUser))
				const redirectUrl = query.get(REDIRECT_URL_KEY);

				navigate(redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath)
				return user;
			}



			//const token = await user.getIdToken(true);
			// Update user in state
			//setUser(user);

		} catch (err) {
			return {
				status: 'failed',
				message: err?.response?.data?.message || err.toString()
			}
		}

	};

	const handleSignOut = ()  => {
		dispatch(onSignOutSuccess())
		dispatch(setUser(initialState))
		navigate(appConfig.unAuthenticatedEntryPath)
	}

	const signUp = (email, password) => {
		return createUserWithEmailAndPassword(auth, email, password).then(
			handleAuth
		);
	};

	const signIn = async (email, password) => {
		try {
		return signInWithEmailAndPassword(auth, email, password).then(handleAuth);
		} catch (err) {
			return {
				status: 'failed',
				message: err?.response?.data?.message || err.toString()
			}
		}

	};

	const signInWithProvider = (name) => {
		// Get provider by name ("google", "facebook", etc)
		const provider = authProviders.find((p) => p.name === name).get();
		return signInWithPopup(auth, provider).then(handleAuth);
	};

	const signOut = () => {
		handleSignOut();
		return authSignOut(auth);
	};

	const sendPasswordResetEmail = (email) => {
		return authSendPasswordResetEmail(auth, email);
	};

	const confirmPasswordReset = (password, code) => {
		// Get code from query string object
		const resetCode = code || getFromQueryString("oobCode");
		return authConfirmPasswordReset(auth, resetCode, password);
	};

	const updatePassword = (password) => {
		return authUpdatePassword(auth.currentUser, password);
	};

	// Update auth user and persist data to database
	// Call this function instead of multiple auth/db update functions
	const updateProfile = async (data) => {
		const { email, username, avatarUrl } = data;

		// Update auth email
		if (email) {
			await authUpdateEmail(auth.currentUser, email);
		}

		// Update built-in auth profile fields
		// These fields are renamed in `useFormatUser`, so when updating we
		// need to make sure to use their original names (`displayName`, `photoURL`, etc)
		if (username || avatarUrl) {
			let fields = {};
			if (username) fields.username = username;
			if (avatarUrl) fields.avatarUrl = avatarUrl;
			await authUpdateProfile(auth.currentUser, fields);
		}
		// Persist all data to the database
		await updateUser(user.uid, data);

		// Update user in state
		setUserAuth(auth.currentUser);
		//dispatch(setUser(auth.currentUser));
	};

	useEffect(() => {
		// Subscribe to user on mount
		const unsubscribe = onAuthStateChanged(auth, async (user) => {
			if (user) {
				const token = await user.getIdToken(true);
				Cookies.set('token', token)
				const registeredUser = {
					id: user.uid,
					avatarUrl: user.avatarUrl,
					username: user.username,
					email: user.email,
					uid: user.uid,
					authority: ['admin'],
					accountUserName: 'admin',
					...user
				}

				//dispatch(onSignInSuccess(token))
				setUserAuth(registeredUser)
				dispatch(setUser(registeredUser));
			} else {
				//dispatch(setUser(null));
				Cookies.set('token', '')
				setUserAuth(false);
				//setUser();
				//Cookies.set('token', '')

				//nookies.set(undefined, 'token', '', { path: '/' });
			}
		});

		// Unsubscribe on cleanup
		return () => unsubscribe();
	}, []);

	// force refresh the token every 10 minutes
	/* 	useEffect(() => {
		  const handle = setInterval(async () => {
			const user = firebaseClient.auth().currentUser;
			if (user) await user.getIdToken(true);
		  }, 10 * 60 * 1000);
	  
		  // clean up setInterval
		  return () => clearInterval(handle);
		}, []); */

	return {
		user: finalUser,
		authenticated: signedIn && token,
		signUp,
		signIn,
		signInWithProvider,
		signOut,
		sendPasswordResetEmail,
		confirmPasswordReset,
		updatePassword,
		updateProfile,
	};
}

function useFormatUser(user) {
	// Memoize so returned object has a stable identity
	return useMemo(() => {
		// Return if auth user is `null` (loading) or `false` (not authenticated)
		if (!user) return user;

		// Create an array of user's auth providers by id (["password", "google", etc])
		// Components can read this to prompt user to re-auth with the correct provider
		if (user.providerData) {
			const providers = user.providerData.map(({ providerId }) => {
				return authProviders.find((p) => p.id === providerId).name;
			});
			return {
				// Include full auth user data
				...user,
				// Alter the names of some fields
				username: user.username,
				avatarUrl: user.avatarUrl,
				// User's auth providers
				providers: providers,
			};
		}
	}, [user]);
}

function useMergeExtraData(user, { enabled }) {
	// Get extra user data from database
	const { data, status, error } = useUser(enabled && user && user.uid);
	// Memoize so returned object has a stable identity
	return useMemo(() => {
		// If disabled or no auth user (yet) then just return
		if (!enabled || !user) return user;

		switch (status) {
			case "success":
				// If successful, but `data` is `null`, that means user just signed up and the `createUser`
				// function hasn't populated the db yet. Return `null` to indicate auth is still loading.
				// The above call to `useUser` will re-render things once the data comes in.
				if (data === null) return null;
				// Return auth `user` merged with extra user `data`
				return { ...user, ...data };
			case "error":
				// Uh oh.. Let's at least show a helpful error.
				throw new Error(`
			Error: ${error.message}
			This happened while attempting to fetch extra user data from the database
			to include with the authenticated user. Make sure the database is setup or
			disable merging extra user data by setting MERGE_DB_USER to false.
		  `);
			default:
				// We have an `idle` or `loading` status so return `null`
				// to indicate that auth is still loading.
				return null;
		}
	}, [user, enabled, data, status, error]);
}

// Connect analytics session to current user
function useIdentifyUser(user, { enabled }) {
	useEffect(() => {
		if (user && enabled) {
			//analytics.identify(user.uid);
		}
	}, [user, enabled]);
}

// A Higher Order Component for requiring authentication
export const requireAuth = (Component) => {
	return function RequireAuthHOC(props) {
		// Get authenticated user
		const auth = useAuth();
		const navigate = useNavigate();

		useEffect(() => {
			// Redirect if not signed in
			if (!auth.authenticated) {
				navigate("/auth/signin");
			}
		}, [auth]);

		// Show loading indicator
		// We're either loading (user is `null`) or about to redirect from above `useEffect` (user is `false`)
		if (!auth.user) {
			return <></>;
		}

		// Render component now that we have user
		return <Component {...props} />;
	};
};

// Handle Firebase email link for reverting to original email after an email change
export const handleRecoverEmail = async (code) => {
	// Check that action code is valid
	const info = await checkActionCode(auth, code);
	// Revert to original email by applying action code
	await applyActionCode(auth, code);
	// Send password reset email so user can change their password in the case
	// that someone else got into their account and changed their email.
	await authSendPasswordResetEmail(auth, info.data.email);
	// Return original email so it can be displayed by calling component
	return info.data.email;
};

// Handle Firebase email link for verifying email
export const handleVerifyEmail = (code) => {
	return applyActionCode(auth, code);
};

const authProviders = [
	{
		id: "password",
		name: "password",
	},
	{
		id: "google.com",
		name: "google",
		get: () => new GoogleAuthProvider(),
	},
	{
		id: "facebook.com",
		name: "facebook",
		get: () => {
			const provider = new FacebookAuthProvider();
			provider.setCustomParameters({ display: "popup" });
			return provider;
		},
	},
	{
		id: "twitter.com",
		name: "twitter",
		get: () => new TwitterAuthProvider(),
	},
	{
		id: "github.com",
		name: "github",
		get: () => new GithubAuthProvider(),
	},
];

// Wait for Firebase user to be initialized before resolving promise
// and taking any further action (such as writing to the database)
const waitForFirebase = (uid) => {
	return new Promise((resolve) => {
		const unsubscribe = onAuthStateChanged(auth, (user) => {
			// Ensure we have a user with expected `uid`
			if (user && user.uid === uid) {
				resolve(user); // Resolve promise
				unsubscribe(); // Prevent from firing again
			}
		});
	});
};

const getFromQueryString = (key) => {
	return queryString.parse(window.location.search)[key];
};


export default useAuth