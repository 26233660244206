import { initializeApp, getApps, getApp } from "firebase/app";
import { getFirestore } from 'firebase/firestore';
import { getAnalytics } from 'firebase/analytics';
//import { initializeApp } from 'firebase-admin/app';

/*const serviceAccount = require('./key.json');
initializeApp({ credential: admin.credential.cert(serviceAccount) });*/

let app;
let analytics;
let firestore;

const fireBaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: "ikuzo-9999.appspot.com",
  messagingSenderId: "366295706334",
  appId: "1:366295706334:web:bd589bce12ccd4661ef04f",
  measurementId: "G-39KT7VLMG9"
};

if (getApps().length === 0) {
  // Initialize Firebase app
  app = initializeApp(fireBaseConfig);
  if (app.name && typeof window !== 'undefined') {
    analytics = getAnalytics(app);
  }
  // Access Firebase services using shorthand notation
  firestore = getFirestore();
} else {
  // Use existing app if already initialized
  app = getApp();
}

const firebaseApp = app;

export { analytics, firestore, firebaseApp };